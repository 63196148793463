@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none;
}

body {
  font-family: var(--st-font-family-squid-main), sans-serif;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.table-padding-x > :not([hidden]) ~ :not([hidden]) {
  padding-left: calc(30px);
}

a:hover {
  text-decoration: underline !important;
}

/* temp override of input selection background */
.selection\:tw-bg-input-selection::selection {
  background-color: var(--st-color-grey-700, #accef7) !important;
}

select {
  @apply tw-bg-input-bg;
}

input[type="date"] {
  @apply tw-bg-input-bg;
}

@keyframes flame-flicker {
  0% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(1.1) translateY(-1px);
  }
}

.order-row:hover .fire-icon {
  animation: flame-flicker 0.6s infinite ease-in-out;
}
